import React from 'react'
// import Search from './Search'
// import Actions from './Actions'
import UserMenu from './UserMenu'
import style from './style.module.scss'

const TopBar = () => {
  return (
    <div className={style.topbar}>
      <div className="mr-auto">
        {/* <Search /> */}
        IAS-Support
      </div>
      <div className="ml-auto mr-sm-4">
        {/* <button
          type="button"
          className="btn btn-primary btn-with-addon mr-auto text-nowrap d-none d-md-block"
        >
          <span className="btn-addon">
            <i className="btn-addon-icon fe fe-phone" />
          </span>
          Live Call Info
        </button> */}
      </div>

      <div className="mr-sm-4 d-none d-sm-block">{/* <Actions /> */}</div>
      <div className="">
        <UserMenu />
      </div>
    </div>
  )
}

export default TopBar
