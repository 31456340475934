import apiClient from 'services/axios'

export async function apiCallPost(url, params) {
  if (params instanceof FormData) {
    params.append('version', process.env.REACT_APP_FOOTER)
  } else {
    params.version = process.env.REACT_APP_FOOTER
  }
  return apiClient
    .post(url, params)
    .then((response) => {
      if (response) {
        return response
      }
      return false
    })
    .catch((err) => console.log(err))
}
export async function apiCallPut(url, params) {
  params.version = process.env.REACT_APP_FOOTER
  return apiClient
    .put(url, params)
    .then((response) => {
      if (response) {
        return response
      }
      return false
    })
    .catch((err) => console.log(err))
}

export async function apiCallDelete(url) {
  return apiClient
    .delete(url)
    .then((response) => {
      if (response) {
        return response
      }
      return false
    })
    .catch((err) => console.log(err))
}

export default async function apiCall(url, params) {
  if (params) {
    params.params.version = process.env.REACT_APP_FOOTER
  } else {
    params = { params: {} }
    params.params.version = process.env.REACT_APP_FOOTER
  }
  return apiClient
    .get(url, params)
    .then((response) => {
      if (response) {
        return response
      }
      return false
    })
    .catch((err) => console.log(err))
}
