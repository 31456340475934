import React, { lazy, Suspense, useEffect, useState } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect, useSelector } from 'react-redux'
import apiCall from 'services/ias'

import Layout from 'layouts'

const routes = [
  // VB:REPLACE-START:ROUTER-CONFIG
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
    permission: 'dashboard/view',
  },
  {
    path: '/agent-dashboard',
    Component: lazy(() => import('pages/agents/dashboard')),
    exact: true,
    permission: 'agent/dashboard',
  },
  {
    path: '/temp/customers/list',
    Component: lazy(() => import('pages/admin/user-manager/index')),
    exact: true,
    permission: 'user/view',
  },
  {
    path: '/temp/webapp/plan-requests',
    Component: lazy(() => import('pages/admin/tempplanrequests/TempPlanRequestIndex')),
    exact: true,
    permission: 'userplan/view',
  },
  {
    path: '/admin/agent/index',
    Component: lazy(() => import('pages/admin/agent/AgentIndex')),
    exact: true,
    permission: 'agent/view',
  },
  {
    path: '/admin/agent/permission',
    Component: lazy(() => import('pages/admin/agent/AgentPermission')),
    exact: true,
    permission: 'agent/view',
  },
  {
    path: '/admin/agent/campaign',
    Component: lazy(() => import('pages/admin/agent/AgentCampaign')),
    exact: true,
    permission: 'agent/campaigns/view',
  },
  {
    path: '/admin/webinar/create',
    Component: lazy(() => import('pages/admin/webinar/create')),
    exact: true,
    permission: 'webinar/view',
  },
  {
    path: '/admin/deal/create',
    Component: lazy(() => import('pages/admin/deal/Index')),
    exact: true,
    permission: 'deal/view',
  },
  {
    path: '/admin/reports/list',
    Component: lazy(() => import('pages/admin/reports/index')),
    exact: true,
    permission: 'reports/view',
  },
  {
    path: '/admin/customer/index',
    Component: lazy(() => import('pages/admin/customer/CustomerIndex')),
    exact: true,
    permission: 'customer/view',
  },
  {
    path: '/admin/user/index',
    Component: lazy(() => import('pages/admin/user/UserMergeIndex')),
    exact: true,
    permission: 'customer/view',
  },
  {
    path: '/admin/order/create',
    Component: lazy(() => import('pages/admin/order/Index')),
    exact: true,
    permission: 'order/view',
  },
  {
    path: '/admin/invoice/list',
    Component: lazy(() => import('pages/admin/invoice/Index')),
    exact: true,
    permission: 'invoice/view',
  },
  {
    path: '/admin/profile',
    Component: lazy(() => import('pages/admin/profile/ProfileIndex')),
    exact: true,
    permission: 'profile/view',
  },
  {
    path: '/admin/webapp/plan-view',
    Component: lazy(() => import('pages/admin/webapp/WebappPlanPermission')),
    exact: true,
    permission: 'webapp/plan/view',
  },
  {
    path: '/admin/csv/import',
    Component: lazy(() => import('pages/admin/csv/CSVIndex')),
    exact: true,
    permission: 'csv/import',
  },
  {
    path: '/dashboard/alpha',
    Component: lazy(() => import('pages/dashboard/alpha')),
    exact: true,
  },
  {
    path: '/dashboard/beta',
    Component: lazy(() => import('pages/dashboard/beta')),
    exact: true,
  },
  {
    path: '/dashboard/gamma',
    Component: lazy(() => import('pages/dashboard/gamma')),
    exact: true,
  },
  {
    path: '/dashboard/crypto',
    Component: lazy(() => import('pages/dashboard/crypto')),
    exact: true,
  },
  {
    path: '/apps',
    Component: lazy(() => import('pages/apps')),
    exact: true,
  },
  {
    path: '/apps/profile',
    Component: lazy(() => import('pages/apps/profile')),
    exact: true,
  },
  {
    path: '/apps/calendar',
    Component: lazy(() => import('pages/apps/calendar')),
    exact: true,
  },
  {
    path: '/apps/gallery',
    Component: lazy(() => import('pages/apps/gallery')),
    exact: true,
  },
  {
    path: '/apps/messaging',
    Component: lazy(() => import('pages/apps/messaging')),
    exact: true,
  },
  {
    path: '/apps/mail',
    Component: lazy(() => import('pages/apps/mail')),
    exact: true,
  },
  {
    path: '/extra-apps',
    Component: lazy(() => import('pages/extra-apps')),
    exact: true,
  },
  {
    path: '/extra-apps/github-explore',
    Component: lazy(() => import('pages/extra-apps/github-explore')),
    exact: true,
  },
  {
    path: '/extra-apps/github-discuss',
    Component: lazy(() => import('pages/extra-apps/github-discuss')),
    exact: true,
  },
  {
    path: '/extra-apps/digitalocean-droplets',
    Component: lazy(() => import('pages/extra-apps/digitalocean-droplets')),
    exact: true,
  },
  {
    path: '/extra-apps/digitalocean-create',
    Component: lazy(() => import('pages/extra-apps/digitalocean-create')),
    exact: true,
  },
  {
    path: '/extra-apps/google-analytics',
    Component: lazy(() => import('pages/extra-apps/google-analytics')),
    exact: true,
  },
  {
    path: '/extra-apps/wordpress-post',
    Component: lazy(() => import('pages/extra-apps/wordpress-post')),
    exact: true,
  },
  {
    path: '/extra-apps/wordpress-posts',
    Component: lazy(() => import('pages/extra-apps/wordpress-posts')),
    exact: true,
  },
  {
    path: '/extra-apps/wordpress-add',
    Component: lazy(() => import('pages/extra-apps/wordpress-add')),
    exact: true,
  },
  {
    path: '/extra-apps/todoist-list',
    Component: lazy(() => import('pages/extra-apps/todoist-list')),
    exact: true,
  },
  {
    path: '/extra-apps/jira-dashboard',
    Component: lazy(() => import('pages/extra-apps/jira-dashboard')),
    exact: true,
  },
  {
    path: '/extra-apps/jira-agile-board',
    Component: lazy(() => import('pages/extra-apps/jira-agile-board')),
    exact: true,
  },
  {
    path: '/extra-apps/helpdesk-dashboard',
    Component: lazy(() => import('pages/extra-apps/helpdesk-dashboard')),
    exact: true,
  },
  {
    path: '/ecommerce',
    Component: lazy(() => import('pages/ecommerce')),
    exact: true,
  },
  {
    path: '/ecommerce/dashboard',
    Component: lazy(() => import('pages/ecommerce/dashboard')),
    exact: true,
  },
  {
    path: '/ecommerce/orders',
    Component: lazy(() => import('pages/ecommerce/orders')),
    exact: true,
  },
  {
    path: '/ecommerce/product-catalog',
    Component: lazy(() => import('pages/ecommerce/product-catalog')),
    exact: true,
  },
  {
    path: '/ecommerce/product-details',
    Component: lazy(() => import('pages/ecommerce/product-details')),
    exact: true,
  },
  {
    path: '/ecommerce/cart',
    Component: lazy(() => import('pages/ecommerce/cart')),
    exact: true,
  },
  {
    path: '/ui-kits/antd',
    Component: lazy(() => import('pages/ui-kits/antd')),
    exact: true,
  },
  {
    path: '/ui-kits/bootstrap',
    Component: lazy(() => import('pages/ui-kits/bootstrap')),
    exact: true,
  },
  {
    path: '/widgets',
    Component: lazy(() => import('pages/widgets')),
    exact: true,
  },
  {
    path: '/widgets/general',
    Component: lazy(() => import('pages/widgets/general')),
    exact: true,
  },
  {
    path: '/widgets/lists',
    Component: lazy(() => import('pages/widgets/lists')),
    exact: true,
  },
  {
    path: '/widgets/tables',
    Component: lazy(() => import('pages/widgets/tables')),
    exact: true,
  },
  {
    path: '/widgets/charts',
    Component: lazy(() => import('pages/widgets/charts')),
    exact: true,
  },
  {
    path: '/cards',
    Component: lazy(() => import('pages/cards')),
    exact: true,
  },
  {
    path: '/cards/basic',
    Component: lazy(() => import('pages/cards/basic')),
    exact: true,
  },
  {
    path: '/cards/tabbed',
    Component: lazy(() => import('pages/cards/tabbed')),
    exact: true,
  },
  {
    path: '/tables',
    Component: lazy(() => import('pages/tables')),
    exact: true,
  },
  {
    path: '/tables/antd',
    Component: lazy(() => import('pages/tables/antd')),
    exact: true,
  },
  {
    path: '/tables/bootstrap',
    Component: lazy(() => import('pages/tables/bootstrap')),
    exact: true,
  },
  {
    path: '/charts',
    Component: lazy(() => import('pages/charts')),
    exact: true,
  },
  {
    path: '/charts/chartistjs',
    Component: lazy(() => import('pages/charts/chartistjs')),
    exact: true,
  },
  {
    path: '/charts/chartjs',
    Component: lazy(() => import('pages/charts/chartjs')),
    exact: true,
  },
  {
    path: '/charts/C3',
    Component: lazy(() => import('pages/charts/C3')),
    exact: true,
  },
  {
    path: '/icons',
    Component: lazy(() => import('pages/icons')),
    exact: true,
  },
  {
    path: '/icons/feather-icons',
    Component: lazy(() => import('pages/icons/feather-icons')),
    exact: true,
  },
  {
    path: '/icons/fontawesome',
    Component: lazy(() => import('pages/icons/fontawesome')),
    exact: true,
  },
  {
    path: '/icons/linearicons-free',
    Component: lazy(() => import('pages/icons/linearicons-free')),
    exact: true,
  },
  {
    path: '/icons/icomoon-free',
    Component: lazy(() => import('pages/icons/icomoon-free')),
    exact: true,
  },
  {
    path: '/advanced/form-examples',
    Component: lazy(() => import('pages/advanced/form-examples')),
    exact: true,
  },
  {
    path: '/advanced/email-templates',
    Component: lazy(() => import('pages/advanced/email-templates')),
    exact: true,
  },
  {
    path: '/advanced/pricing-tables',
    Component: lazy(() => import('pages/advanced/pricing-tables')),
    exact: true,
  },
  {
    path: '/advanced/invoice',
    Component: lazy(() => import('pages/advanced/invoice')),
    exact: true,
  },
  {
    path: '/advanced/utilities',
    Component: lazy(() => import('pages/advanced/utilities')),
    exact: true,
  },
  {
    path: '/advanced/grid',
    Component: lazy(() => import('pages/advanced/grid')),
    exact: true,
  },
  {
    path: '/advanced/typography',
    Component: lazy(() => import('pages/advanced/typography')),
    exact: true,
  },
  {
    path: '/advanced/colors',
    Component: lazy(() => import('pages/advanced/colors')),
    exact: true,
  },
  {
    path: '/nested',
    Component: lazy(() => import('pages/nested')),
    exact: true,
  },
  {
    path: '/nested/1',
    Component: lazy(() => import('pages/nested/1')),
    exact: true,
  },
  {
    path: '/nested/2',
    Component: lazy(() => import('pages/nested/2')),
    exact: true,
  },

  // VB:REPLACE-END:ROUTER-CONFIG
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
    permission: '*',
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
    permission: '*',
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
    permission: '*',
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
    permission: '*',
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
    permission: '*',
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
    permission: '*',
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  const user = useSelector((state) => state.user)
  const [agentMap, setAgentMap] = useState([])
  const [optionAgentList, setOptionAgentList] = useState([])
  const [optionProductsList, setOptionProductsList] = useState([])
  const [optionPaymentMethods, setOptionPaymentMethods] = useState([])
  const [optionStates, setOptionStates] = useState([])
  const [conversionRates, setConversionRates] = useState([])
  const [usdConversionRate, setUsdConversionRate] = useState(1)
  const [orderTypeData, setOrderTypesData] = useState([])
  const [optionProducts, setOptionProducts] = useState([])
  const [languageList, setLanguageData] = useState([])
  const fetchData = () => {
    getAgents()
    getProducts()
    getPaymentGateways()
    getStateList()
    getConversionRates()
    getOrderType()
    getLanguages()
    console.log('log-user', user)
  }
  useEffect(() => {
    if (user.id) {
      fetchData()
    }
  }, [user.id])
  const getAgents = async () => {
    const res = await apiCall(`/view/all-users`)
    if (res.data && res.data.user) {
      const idNameMap = res.data.user.reduce((acc, item) => {
        acc[item.id] = item.name
        return acc
      }, {})
      setAgentMap(idNameMap)
      const activeUsers = res.data.user.filter((sUser) => sUser.active === 1)
      setOptionAgentList(
        activeUsers.map((item) => {
          return {
            value: item.id,
            label: item.name,
          }
        }),
      )
    }
    return res?.data.user ? res.data.user : []
  }
  const getProducts = async () => {
    const resProducts = await apiCall(`/product`)
    if (resProducts.data && resProducts.data.data) {
      setOptionProductsList(
        resProducts.data.data
          .filter((item) => item.plan_id !== 0)
          .map((item) => {
            return {
              value: item.plan_id,
              label: item.product_name,
              INR: item.INR_amount,
              USD: item.USD_amount,
            }
          }),
      )
      setOptionProductsList((prevOptionProductsList) => [
        ...prevOptionProductsList,
        { key: 'noPlan', value: 'noPlan', label: 'No Plan' },
      ])
      setOptionProducts(
        resProducts.data.data
          .filter((item) => item.plan_id !== 0)
          .map((item) => {
            return {
              value: item.plan_id,
              label: item.product_name,
              INR: item.INR_amount,
              USD: item.USD_amount,
            }
          }),
      )
    }
  }
  const getPaymentGateways = async () => {
    const resPaymentGateways = await apiCall(`/payment-gateways`)
    if (resPaymentGateways.data && resPaymentGateways.data.data) {
      if (resPaymentGateways.data.data.includes('["')) {
        resPaymentGateways.data.data = JSON.parse(resPaymentGateways.data.data)
      }

      setOptionPaymentMethods(
        resPaymentGateways.data.data.map((item) => {
          return { value: item, label: item }
        }),
      )
    }
  }
  const getStateList = async () => {
    const resState = await apiCall(`/stateList`)
    if (resState.data && resState.data.data) {
      setOptionStates(
        resState.data.data
          .filter((item) => item.plan_id !== 0)
          .map((item) => {
            return {
              value: item.code,
              label: item.state,
            }
          }),
      )
    }
  }
  const getConversionRates = async () => {
    const resConversion = await apiCall(`/conversion-rates`)
    if (resConversion.data && resConversion.data.data) {
      setConversionRates(resConversion.data.data.conversion_rates)
      setUsdConversionRate(resConversion.data.data.conversion_rates.USD)
    }
  }
  const getOrderType = async () => {
    const orderTypeRes = await apiCall(`/order-type`)
    if (orderTypeRes.data && orderTypeRes.data.data) {
      const orderTypeList = orderTypeRes.data.data.filter((item) => item.label !== 'Revert')
      setOrderTypesData(
        orderTypeList.map((item) => {
          return {
            label: item.label,
            value: item.id,
          }
        }),
      )
    }
  }
  const getLanguages = async () => {
    const reslanguages = await apiCall(`/languages`)
    if (reslanguages.data && reslanguages.data.all_language) {
      setLanguageData(
        reslanguages.data.all_language.map((item) => {
          return {
            label: item.label,
            value: item.id,
          }
        }),
      )
    }
  }
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state
            const userRoutes = routes.filter(
              (route) =>
                (route.permission && user.can(route.permission)) || route.permission === '*',
            )
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {/* VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT */}
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    {userRoutes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={(props) => {
                          let componentProps = { ...props }
                          if (path === '/admin/customer/index') {
                            componentProps = {
                              ...props,
                              agentMap,
                              optionAgentList,
                              optionProductsList,
                              optionPaymentMethods,
                              optionStates,
                              conversionRates,
                              usdConversionRate,
                              orderTypeData,
                              optionProducts,
                              languageList,
                            }
                          } else if (path === '/temp/webapp/plan-requests') {
                            componentProps = {
                              ...props,
                              optionAgentList,
                            }
                          } else if (path === '/admin/agent/campaign') {
                            componentProps = {
                              ...props,
                              optionAgentList,
                            }
                          } else if (path === '/admin/user/index') {
                            componentProps = {
                              ...props,
                              optionAgentList,
                              optionStates,
                              languageList,
                            }
                          } else if (path === '/temp/customers/list') {
                            componentProps = {
                              ...props,
                              agentMap,
                              optionAgentList,
                              languageList,
                              optionStates,
                            }
                          }
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component {...componentProps} />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
