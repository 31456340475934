export default async function getMenuData() {
  return [
    // VB:REPLACE-START:MENU-CONFIG
    {
      title: 'Dashboard',
      key: 'iasepr',
      url: '/dashboard',
      icon: 'fe fe-home',
      permission: 'dashboard/view',
    },
    {
      category: true,
      title: 'Support Center',
      key: '2t2ghm',
      permission: '*',
    },
    {
      title: 'Search Customer',
      key: 'iasaum_search_customer',
      url: '/temp/customers/list',
      icon: 'fe fe-user-check',
      permission: 'user/view',
    },
    {
      title: 'Plan Requests',
      key: 'iasaum_plan_requests',
      url: '/temp/webapp/plan-requests',
      icon: 'fe fe-user-check',
      permission: 'userplan/view',
    },
    {
      title: 'Agent',
      key: 'iasagent',
      url: '/admin/agent/#',
      icon: 'fe fe-user',
      permission: 'agent/view',
      children: [
        {
          title: 'All Agents',
          key: 'iasagent_all_deal',
          url: '/admin/agent/index',
          permission: 'agent/view',
        },
        {
          title: 'User Permission',
          key: 'iasagent_todays_eal',
          url: '/admin/agent/permission',
          permission: 'agent/permission/view',
        },
        {
          title: 'User Campaigns',
          key: 'iasagent_todays_campaign',
          url: '/admin/agent/campaign',
          permission: 'agent/campaigns/view',
        },
      ],
    },
    {
      title: 'My Customers',
      key: 'iascustomers',
      url: '/admin/customer/index',
      icon: 'fe fe-users',
      permission: 'customer/view',
    },
    {
      title: 'Webinar',
      key: 'iaswebinarc',
      url: '/admin/webinar/create',
      icon: 'fe fe-play-circle',
      permission: 'webinar/view',
    },
    {
      title: 'Deal',
      key: 'iaswebdeal',
      url: '/admin/deal/create',
      icon: 'fe fe-briefcase',
      permission: 'deal/view',
      // children: [
      //   {
      //     title: 'All Deals',
      //     key: 'iaswebdeal_all_deal',
      //     url: '/admin/deal/create',
      //   },
      //   {
      //     title: "Today's Deal",
      //     key: 'iaswebdeal_todays_eal',
      //     url: '/admin/deal/todays-deal',
      //   },
      // ],
    },
    {
      title: 'Order',
      key: 'iasorder',
      url: '/admin/order/create',
      icon: 'fe fe-shopping-bag',
      permission: 'order/view',
    },
    {
      title: 'Invoice',
      key: 'iasinvoice',
      url: '/admin/invoice/list',
      icon: 'fe fe-file-text',
      permission: 'invoice/view',
    },
    {
      title: 'Reports',
      key: 'iasrep',
      url: '/admin/reports/list',
      icon: 'fe fe-trending-up',
      permission: 'reports/view',
    },
    {
      title: 'Merge Users',
      key: 'iasmergeusers',
      url: '/admin/user/index',
      icon: 'fe fe-user-plus',
      permission: 'user/merge',
    },
    {
      title: 'Plan Permission',
      key: 'iasrep',
      url: '/admin/webapp/plan-view',
      icon: 'fe fe-check-square',
      permission: 'webapp/plan/view',
    },
    {
      title: 'Bulk Assign',
      key: 'iascsv',
      url: '/admin/csv/import',
      icon: 'fe fe-download',
      permission: 'csv/import',
    },
    {
      category: true,
      title: 'Call Center',
      key: '2t2ghm',
      roles: [100],
    },
    {
      title: 'Agent Dashboard',
      key: 'iasadb',
      url: '/agent-dashboard',
      icon: 'fe fe-home',
      roles: [100],
    },
    {
      category: true,
      title: 'WebApp Data',
      key: '2t2ghm',
      roles: [100],
    },
    {
      title: 'Contacts',
      key: '6rq4ze6',
      url: '/apps',
      icon: 'fe fe-users',
      roles: [100],
      children: [
        {
          title: 'Unverified Users',
          key: 'e38wke',
          url: '/apps/profile',
        },
        {
          title: 'Free Users',
          key: 'y08dt9',
          url: '/apps/calendar',
        },
        {
          title: 'Paid Users',
          key: 'lzh7g9',
          url: '/apps/gallery',
        },
        {
          title: 'Expired Users',
          key: 'jdwic9',
          url: '/apps/messaging',
        },
        {
          title: 'Expiring Users',
          key: '4dwoxc',
          url: '/apps/mail',
        },
      ],
    },
    {
      title: 'Orders',
      key: 'c20gta',
      url: '/extra-apps',
      icon: 'fe fe-credit-card',
      roles: [100],
      children: [
        {
          title: 'Completed',
          key: '8ba63s',
          url: '/extra-apps/github-explore',
        },
        {
          title: 'Failed',
          key: 'b0rmp9',
          url: '/extra-apps/github-discuss',
        },
        {
          title: 'Attempts',
          key: 'cmzpxs',
          url: '/extra-apps/digitalocean-droplets',
        },
      ],
    },
    {
      category: true,
      title: 'Management',
      key: 'evz6c8',
      roles: [100],
    },
    {
      title: 'Agents management',
      key: 'qelxw',
      url: '/ui-kits/antd',
      icon: 'fe fe-headphones',
      roles: [100],
    },
    {
      title: 'Data Management',
      key: 'l1gqx8',
      url: '/ui-kits/bootstrap',
      icon: 'fe fe-hard-drive',
      roles: [100],
    },
    {
      title: 'View Logs',
      key: 'l1gqx1',
      url: '/ui-kits/bootstrap',
      icon: 'fe fe-activity',
      roles: [100],
    },

    // VB:REPLACE-END:MENU-CONFIG
  ]
}
