import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Layout } from 'antd'
import classNames from 'classnames'
import store from 'store'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { find } from 'lodash'
import style from './style.module.scss'

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: menu.menuData,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isMenuUnfixed: settings.isMenuUnfixed,
  isMenuShadow: settings.isMenuShadow,
  leftMenuWidth: settings.leftMenuWidth,
  menuColor: settings.menuColor,
  logo: settings.logo,
  version: settings.version,
  role: user.role,
  user,
})

const MenuLeft = ({
  dispatch,
  menuData = [],
  location: { pathname },

  isMenuCollapsed,
  isMobileView,
  isMenuUnfixed,
  isMenuShadow,
  leftMenuWidth,
  menuColor,
  logo,
  version,
  role,
  user,
}) => {
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])
  const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || [])

  useEffect(() => {
    applySelectedKeys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData])

  const applySelectedKeys = () => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname])
    setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  }

  const onCollapse = (value, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })
    setOpenedKeys([])
  }

  const onOpenChange = (keys) => {
    store.set('app.menu.openedKeys', keys)
    setOpenedKeys(keys)
  }

  const handleClick = (e) => {
    store.set('app.menu.selectedKeys', [e.key])
    setSelectedKeys([e.key])
  }

  const generateMenuItems = () => {
    const generateItem = (item) => {
      const { key, title, url, icon, disabled, count } = item
      if (item.category) {
        return <Menu.ItemGroup key={Math.random()} title={item.title} />
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target && (
              <a href={url} target={item.target} rel="noopener noreferrer">
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
                {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
              </a>
            )}
            {!item.target && (
              <Link to={url}>
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
                {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
              </Link>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          <span className={style.title}>{title}</span>
          {count && <span className="badge badge-success ml-2">{count}</span>}
          {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
        </Menu.Item>
      )
    }

    const generateSubmenu = (items) =>
      items.map((menuItem) => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={style.title}>{menuItem.title}</span>
              {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
              {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
            </span>
          )
          return (
            <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </Menu.SubMenu>
          )
        }
        return generateItem(menuItem)
      })

    return menuData.map((menuItem) => {
      if (!user.can(menuItem.permission) && menuItem.permission !== '*') {
        return null
      }
      if (menuItem.roles && !menuItem.roles.includes(role)) {
        return null
      }
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={style.title}>{menuItem.title}</span>
            {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
            {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
          </span>
        )
        return (
          <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  const menuSettings = isMobileView
    ? {
        width: leftMenuWidth,
        collapsible: false,
        collapsed: false,
        onCollapse,
      }
    : {
        width: leftMenuWidth,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse,
        breakpoint: 'lg',
      }

  return (
    <Layout.Sider
      {...menuSettings}
      className={classNames(`${style.menu}`, {
        [style.white]: menuColor === 'white',
        [style.gray]: menuColor === 'gray',
        [style.dark]: menuColor === 'dark',
        [style.unfixed]: isMenuUnfixed,
        [style.shadow]: isMenuShadow,
      })}
    >
      <div
        className={style.menuOuter}
        style={{
          width: isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth,
          height: isMobileView || isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)',
        }}
      >
        <div className={style.logoContainer}>
          <div className={style.logo}>
            <svg
              width="122"
              height="50"
              viewBox="0 0 122 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M91.7787 2.62012C84.8759 5.78013 82.0473 7.76007 82.2177 9.18312C82.7723 13.7099 108.761 7.13339 119.434 3.11283C103.959 10.3217 77.3181 15.3358 76.0123 11.4086C75.0404 8.71398 81.8816 5.40763 91.7787 2.62012Z"
                fill="#2E3192"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M90.5907 10.164C97.4934 7.00377 100.322 5.02477 100.152 3.60172C99.5972 -0.925562 73.608 5.65074 62.9351 9.67154C78.4107 2.46248 105.051 -2.55167 106.357 1.37624C107.329 4.07015 100.487 7.37698 90.5907 10.164Z"
                fill="#00AEEF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M112.405 22.4483H114.687V23.2372C115.093 22.9398 115.597 22.7158 116.196 22.568C116.803 22.4184 117.485 22.3433 118.24 22.3433C119.549 22.343 120.505 22.5589 121.103 22.9879C121.704 23.4195 121.999 24.1218 121.999 25.0934V31.0673H119.71V24.9345C119.71 24.2597 119.542 23.798 119.201 23.5462C118.859 23.2941 118.266 23.1683 117.428 23.1683C116.531 23.1681 115.852 23.3506 115.383 23.7168C114.917 24.0821 114.687 24.6203 114.687 25.3308V31.0676H112.405V22.4483Z"
                fill="#00AEEF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M92.5713 22.4478H94.8922V31.0663H92.5713V22.4478Z"
                fill="#00AEEF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M81.4221 29.3696C81.9766 29.6693 82.5513 29.8943 83.1436 30.0476C83.7314 30.1993 84.3303 30.2754 84.9369 30.2754C85.7811 30.2754 86.4778 30.1494 87.0258 29.8978C87.5736 29.6452 87.8439 29.3217 87.8439 28.9231C87.8439 28.4365 86.9005 27.8198 85.0134 27.0694L84.7487 26.9642C83.2517 26.3683 82.2833 25.9017 81.8415 25.5521C81.4004 25.2027 81.1772 24.8232 81.1772 24.4063C81.1772 23.7974 81.6027 23.2967 82.46 22.9009C83.318 22.505 84.4138 22.3066 85.7486 22.3066C86.3163 22.3064 86.8704 22.3442 87.4191 22.4182C87.9662 22.491 88.5084 22.6042 89.037 22.7538V23.8543C88.5728 23.645 88.0768 23.4816 87.5477 23.3717C87.0132 23.2599 86.4909 23.2036 85.9741 23.2036C85.2331 23.2034 84.6059 23.3142 84.1039 23.5367C83.5999 23.7607 83.3502 24.0375 83.3502 24.3732C83.3502 24.8409 84.2206 25.4181 85.9739 26.0947L86.1286 26.1552C87.7771 26.791 88.8396 27.2975 89.3204 27.6695C89.8 28.0404 90.0365 28.4554 90.0365 28.9054C90.0365 29.5916 89.5685 30.1545 88.6372 30.5999C87.7059 31.0442 86.5224 31.2685 85.0841 31.2685C84.4201 31.2685 83.7682 31.2121 83.1303 31.1003C82.4992 30.9899 81.9244 30.8333 81.4219 30.6353V29.3696H81.4221Z"
                fill="#00AEEF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M75.626 22.4478H77.9465V31.0663H75.626V22.4478Z"
                fill="#00AEEF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M59.4927 18.2397H62.0073L65.5931 27.0873C65.6855 27.3158 65.8146 27.6453 65.9471 28.0767C66.0786 28.5054 66.2048 28.9883 66.3341 29.5198C66.4048 28.9465 66.6352 28.1763 66.9914 27.21L67.0306 27.1048L70.4677 18.2397H73.0211L67.4306 31.0665H65.18L59.4927 18.2397Z"
                fill="#00AEEF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.8328 22.4483H36.1152V23.6179C36.4571 23.2131 36.8828 22.9072 37.3921 22.6941C37.9016 22.4842 38.4879 22.3675 39.159 22.3433V23.4944C38.0237 23.5247 37.2311 23.708 36.7857 24.0501C36.3409 24.3879 36.115 25.0124 36.115 25.9183V31.0673H33.8325V22.4483H33.8328Z"
                fill="#00AEEF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.4245 23.3256H24.4961V22.4477H26.4245V20.0386H28.7066V22.4477H31.7696V23.3256H28.7066V31.0663H26.4245V23.3256Z"
                fill="#00AEEF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.8632 29.3696C15.4171 29.6693 15.9912 29.8947 16.5843 30.0476C17.1716 30.2003 17.7707 30.2754 18.3775 30.2754C19.2217 30.2754 19.9184 30.1494 20.466 29.8978C21.0143 29.6452 21.2848 29.3217 21.2848 28.9231C21.2848 28.4365 20.3435 27.8193 18.4546 27.0694L18.1899 26.9642C16.6872 26.3709 15.7203 25.9029 15.2819 25.5521C14.8373 25.2034 14.6177 24.8232 14.6177 24.4063C14.6177 23.7974 15.0434 23.2967 15.9014 22.9009C16.7587 22.505 17.8547 22.3066 19.1893 22.3066C19.7567 22.3064 20.3113 22.3431 20.8593 22.4182C21.4075 22.4903 21.9495 22.6042 22.4777 22.7538V23.8543C22.0132 23.645 21.5168 23.4825 20.9884 23.3717C20.4532 23.2606 19.9311 23.2036 19.415 23.2036C18.6737 23.2034 18.0484 23.3149 17.5452 23.5367C17.042 23.7614 16.7904 24.0375 16.7904 24.3732C16.7904 24.8409 17.6675 25.4141 19.415 26.0947L19.57 26.1552C21.2138 26.7933 22.2777 27.2977 22.7613 27.6695C23.2388 28.0413 23.4771 28.4554 23.4771 28.9054C23.4771 29.5916 23.0131 30.1557 22.0779 30.5999C21.1499 31.0461 19.9633 31.2685 18.525 31.2685C17.8608 31.2685 17.2091 31.2111 16.5708 31.1003C15.9397 30.9894 15.3653 30.8333 14.863 30.6353V29.3696H14.8632Z"
                fill="#00AEEF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M98.3672 26.7458C98.3672 25.2613 98.8153 24.1459 99.7022 23.3958C100.588 22.6458 101.921 22.271 103.694 22.271C105.422 22.2708 106.731 22.6491 107.627 23.4077C108.523 24.1669 108.969 25.2791 108.969 26.746C108.969 28.2213 108.521 29.3367 107.633 30.093C106.748 30.848 105.435 31.2266 103.694 31.2266C101.921 31.2266 100.588 30.8508 99.7022 30.1016C98.8153 29.351 98.3672 28.2335 98.3672 26.7458ZM103.661 23.1857C102.636 23.1857 101.887 23.4676 101.417 24.0285C100.946 24.5919 100.708 25.4982 100.708 26.746C100.708 27.9994 100.946 28.9087 101.417 29.4775C101.887 30.0447 102.636 30.3294 103.661 30.3294C104.655 30.3294 105.395 30.0386 105.88 29.4539C106.362 28.872 106.607 27.9786 106.607 26.7698C106.607 25.5577 106.364 24.6578 105.88 24.0675C105.397 23.4793 104.655 23.1859 103.661 23.1857Z"
                fill="#00AEEF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40.9131 26.7458C40.9131 25.2613 41.3584 24.1457 42.2481 23.3958C43.1316 22.6458 44.4669 22.271 46.2393 22.271C47.9672 22.2708 49.2766 22.6491 50.1726 23.4077C51.0695 24.1669 51.5142 25.2791 51.5142 26.746C51.5142 28.2213 51.0695 29.3367 50.1791 30.093C49.2957 30.8483 47.98 31.2266 46.2393 31.2266C44.4669 31.2266 43.1316 30.8515 42.2481 30.1016C41.3584 29.3519 40.9131 28.2335 40.9131 26.7458ZM46.2071 23.1857C45.1818 23.1857 44.4342 23.4681 43.9632 24.0285C43.4922 24.5926 43.2536 25.4982 43.2536 26.746C43.2536 27.9994 43.4922 28.9082 43.9632 29.4775C44.4344 30.0447 45.1818 30.3294 46.2071 30.3294C47.2002 30.3294 47.9417 30.039 48.4253 29.4539C48.9085 28.872 49.154 27.9786 49.154 26.7698C49.154 25.5577 48.9087 24.6585 48.4253 24.0675C47.9415 23.4795 47.2002 23.1859 46.2071 23.1857Z"
                fill="#00AEEF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.48119 18.2397H7.87985L13.4894 31.0665H10.9362L9.85886 28.4753H3.55306L2.58562 31.0665H0L5.48119 18.2397ZM6.21594 21.4726L3.94564 27.4467H9.38832L7.15748 21.4637C7.07322 21.2294 6.99036 20.9514 6.9124 20.6242C6.84122 20.3004 6.77657 19.9436 6.72569 19.5566C6.66103 19.9251 6.59031 20.2701 6.50605 20.5854C6.4288 20.9033 6.33217 21.1969 6.21594 21.4726Z"
                fill="#00AEEF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M76.8947 18.2578C77.7461 18.2578 78.4412 18.9526 78.4412 19.8048C78.4412 20.6562 77.7461 21.3511 76.8947 21.3511C76.0427 21.3511 75.3481 20.6565 75.3481 19.8048C75.3481 18.9524 76.0427 18.258 76.8947 18.2578Z"
                fill="#00AEEF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M93.7858 18.2578C94.6377 18.2578 95.3325 18.9526 95.3325 19.8048C95.3325 20.6562 94.6377 21.3511 93.7858 21.3511C92.9339 21.3511 92.2393 20.6565 92.2393 19.8048C92.2393 18.9524 92.9339 18.258 93.7858 18.2578Z"
                fill="#00AEEF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.4336 25.54H59.3374V27.1752H54.4336V25.54Z"
                fill="#00AEEF"
              />
              <path
                d="M41.907 35.521H41.3716V34.874H41.907V33.9091L42.7248 33.5942V34.8737H43.9927V35.5207H42.7248V37.8123C42.7248 38.2006 42.7911 38.4772 42.9218 38.6404C43.0528 38.8037 43.2631 38.8861 43.553 38.8861C43.7618 38.8861 43.9796 38.8332 44.2032 38.7258L44.3239 39.4463C43.9857 39.5317 43.6134 39.5758 43.2089 39.5758C42.8427 39.5758 42.5353 39.4391 42.2837 39.1686C42.0321 38.8964 41.9072 38.5538 41.9072 38.1404V35.521H41.907Z"
                fill="#2E3192"
              />
              <path
                d="M47.9568 39.4896V36.5889C47.9568 36.2447 47.8716 35.9725 47.7024 35.7758C47.5332 35.5769 47.2949 35.4772 46.9875 35.4772C46.7889 35.4772 46.5903 35.5361 46.3905 35.6523C46.1904 35.7683 46.0373 35.9037 45.9314 36.0596V39.4896H45.1138V32.9824H45.9314V35.3829C46.0404 35.2137 46.2168 35.0725 46.4596 34.9579C46.7023 34.8447 46.9523 34.7887 47.2111 34.7887C47.6994 34.7887 48.0833 34.9491 48.3599 35.2709C48.6379 35.5932 48.7761 36.0316 48.7761 36.5887V39.4894H47.9568V39.4896Z"
                fill="#2E3192"
              />
              <path
                d="M53.8212 37.2612H50.4943C50.4943 37.8025 50.6427 38.2175 50.9384 38.5071C51.2003 38.7601 51.5355 38.8866 51.9474 38.8866C52.4152 38.8866 52.8063 38.7499 53.1196 38.4777L53.4638 39.0675C53.3373 39.194 53.1431 39.3028 52.8813 39.3954C52.5547 39.5161 52.1902 39.5761 51.7871 39.5761C51.2075 39.5761 50.7148 39.3791 50.309 38.9849C49.859 38.5512 49.6323 37.9687 49.6323 37.2363C49.6323 36.4742 49.8648 35.8639 50.3267 35.405C50.74 34.993 51.2297 34.7886 51.7962 34.7886C52.4537 34.7886 52.9697 34.9739 53.3434 35.3445C53.7052 35.7005 53.8861 36.1726 53.8861 36.7608C53.8861 36.9433 53.8639 37.1095 53.8212 37.2612ZM51.8356 35.4771C51.4694 35.4771 51.1634 35.5961 50.9165 35.8316C50.6812 36.0552 50.5487 36.333 50.5164 36.667H53.0713C53.0713 36.336 52.9683 36.0608 52.7608 35.8405C52.5344 35.5978 52.2254 35.4771 51.8356 35.4771Z"
                fill="#2E3192"
              />
              <path
                d="M60.0136 38.9602C59.6857 39.3705 59.1841 39.5763 58.5105 39.5763C58.1471 39.5763 57.8341 39.4442 57.5676 39.1824C57.3029 38.9191 57.1689 38.5926 57.1689 38.2014C57.1689 37.7336 57.3748 37.338 57.7837 37.0143C58.1924 36.6906 58.7148 36.5291 59.3501 36.5291C59.5223 36.5291 59.7179 36.5671 59.9354 36.6409C59.9354 35.8951 59.6031 35.5215 58.9367 35.5215C58.4249 35.5215 58.0307 35.6585 57.7557 35.9348L57.4101 35.2496C57.566 35.1231 57.7807 35.0157 58.0545 34.9242C58.3294 34.8329 58.5913 34.7891 58.8413 34.7891C59.5107 34.7891 59.9973 34.9403 60.3003 35.2449C60.6032 35.5495 60.7547 36.0333 60.7547 36.6983V38.3531C60.7547 38.7573 60.8754 39.0281 61.1165 39.1621V39.5724C60.7825 39.5724 60.5339 39.5238 60.3693 39.4298C60.2034 39.335 60.0858 39.1777 60.0136 38.9602ZM59.9357 37.2227C59.6768 37.1656 59.496 37.1361 59.393 37.1361C58.9797 37.1361 58.6415 37.2435 58.381 37.4552C58.1191 37.6685 57.9882 37.9199 57.9882 38.2098C57.9882 38.6892 58.2706 38.9289 58.8371 38.9289C59.2504 38.9289 59.6164 38.7317 59.9357 38.3391V37.2227Z"
                fill="#2E3192"
              />
              <path
                d="M61.7402 39.2127L62.0301 38.4378C62.486 38.7363 62.8536 38.8864 63.1332 38.8864C63.6378 38.8864 63.8906 38.6731 63.8906 38.248C63.8906 37.9437 63.6464 37.6818 63.1581 37.4643C62.7817 37.2923 62.5287 37.1613 62.3977 37.0715C62.2666 36.9832 62.1534 36.8817 62.0579 36.7685C61.9608 36.6553 61.8901 36.5346 61.8415 36.4065C61.7946 36.2786 61.771 36.1416 61.771 35.9946C61.771 35.615 61.9092 35.3193 62.1844 35.106C62.461 34.8943 62.8214 34.7886 63.2669 34.7886C63.6025 34.7886 64.0259 34.8943 64.5378 35.106L64.3053 35.865C63.9802 35.6064 63.6539 35.4769 63.3274 35.4769C63.1318 35.4769 62.967 35.5238 62.8333 35.615C62.6995 35.7077 62.6332 35.8242 62.6332 35.9651C62.6332 36.2609 62.801 36.4845 63.1378 36.6373L63.7232 36.9037C64.0822 37.0684 64.3438 37.2538 64.507 37.4641C64.6718 37.6743 64.7527 37.936 64.7527 38.2525C64.7527 38.6658 64.6087 38.9907 64.3175 39.2246C64.0278 39.4585 63.6261 39.5761 63.1115 39.5761C62.6258 39.5759 62.1683 39.4554 61.7402 39.2127Z"
                fill="#2E3192"
              />
              <path
                d="M65.9085 35.521H65.373V34.874H65.9085V33.9091L66.7263 33.5942V34.8737H67.9944V35.5207H66.7263V37.8123C66.7263 38.2006 66.7924 38.4772 66.9235 38.6404C67.0545 38.8037 67.2648 38.8861 67.5544 38.8861C67.7635 38.8861 67.9811 38.8332 68.2044 38.7258L68.3253 39.4463C67.9869 39.5317 67.6149 39.5758 67.2104 39.5758C66.8442 39.5758 66.5368 39.4391 66.2852 39.1686C66.0338 38.8964 65.9087 38.5538 65.9087 38.1404V35.521H65.9085Z"
                fill="#2E3192"
              />
              <path
                d="M71.6229 35.6625C71.445 35.539 71.2641 35.4771 71.083 35.4771C70.7934 35.4771 70.5404 35.6109 70.3226 35.8788C70.1062 36.1463 69.9975 36.4672 69.9975 36.8437V39.4898H69.1782V34.8743H69.9975V35.6111C70.296 35.0624 70.7401 34.7891 71.3285 34.7891C71.4756 34.7891 71.6873 34.8138 71.9625 34.8654L71.6229 35.6625Z"
                fill="#2E3192"
              />
              <path
                d="M72.2422 37.1714C72.2422 36.4579 72.4347 35.8828 72.8189 35.4447C73.2015 35.0077 73.7075 34.7886 74.3323 34.7886C74.9942 34.7886 75.5061 34.9986 75.8709 35.4195C76.2357 35.8403 76.4182 36.4242 76.4182 37.1714C76.4182 37.9154 76.2312 38.5024 75.8578 38.9319C75.4855 39.3614 74.9767 39.5761 74.3325 39.5761C73.675 39.5761 73.1616 39.3583 72.794 38.9247C72.4261 38.4906 72.2422 37.9066 72.2422 37.1714ZM73.1041 37.1714C73.1041 38.3288 73.5145 38.9069 74.3323 38.9069C74.7148 38.9069 75.0148 38.7527 75.2311 38.4422C75.4489 38.1318 75.5563 37.7082 75.5563 37.1716C75.5563 36.0272 75.1487 35.4563 74.3325 35.4563C73.9589 35.4563 73.6617 35.6078 73.4384 35.9124C73.2157 36.2168 73.1041 36.6373 73.1041 37.1714Z"
                fill="#2E3192"
              />
              <path
                d="M77.3447 38.1833V32.9824H78.164V38.0465C78.164 38.2937 78.2347 38.4879 78.3773 38.6305C78.5199 38.7717 78.7052 38.8438 78.9363 38.8438V39.5762C77.8755 39.576 77.3447 39.1113 77.3447 38.1833Z"
                fill="#2E3192"
              />
              <path
                d="M79.5815 37.1714C79.5815 36.4579 79.7741 35.8828 80.1583 35.4447C80.5408 35.0077 81.0468 34.7886 81.6716 34.7886C82.3336 34.7886 82.8454 34.9986 83.2102 35.4195C83.575 35.8403 83.7573 36.4242 83.7573 37.1714C83.7573 37.9154 83.5706 38.5024 83.1972 38.9319C82.8249 39.3614 82.3161 39.5761 81.6719 39.5761C81.0144 39.5761 80.5009 39.3583 80.1333 38.9247C79.7655 38.4906 79.5815 37.9066 79.5815 37.1714ZM80.4435 37.1714C80.4435 38.3288 80.8538 38.9069 81.6716 38.9069C82.0542 38.9069 82.3541 38.7527 82.5705 38.4422C82.7882 38.1318 82.8956 37.7082 82.8956 37.1716C82.8956 36.0272 82.4881 35.4563 81.6719 35.4563C81.2982 35.4563 81.0011 35.6078 80.7775 35.9124C80.5553 36.2168 80.4435 36.6373 80.4435 37.1714Z"
                fill="#2E3192"
              />
              <path
                d="M84.4514 40.7513L84.8956 40.0966C85.3722 40.4157 85.8119 40.5746 86.215 40.5746C86.584 40.5746 86.8767 40.5113 87.0916 40.3834C87.3047 40.2555 87.4121 40.0966 87.4121 39.9068C87.4121 39.5332 87.1416 39.3467 86.6017 39.3467C86.5102 39.3467 86.3429 39.3703 86.1015 39.4158C85.8602 39.4613 85.6718 39.4849 85.5381 39.4849C84.882 39.4849 84.5555 39.2377 84.5555 38.7436C84.5555 38.5921 84.632 38.4539 84.7849 38.3302C84.9394 38.2067 85.1292 38.1169 85.3556 38.0625C84.707 37.7581 84.3818 37.2302 84.3818 36.477C84.3818 35.9946 84.5511 35.5931 84.8862 35.2708C85.223 34.9487 85.638 34.7886 86.1321 34.7886C86.5851 34.7886 86.9411 34.881 87.1957 35.068L87.606 34.5767L88.1401 35.0813L87.6488 35.4519C87.859 35.7241 87.9636 36.0814 87.9636 36.5197C87.9636 36.9861 87.818 37.3756 87.5283 37.6919C87.2387 38.0079 86.8575 38.189 86.3868 38.2345L85.7104 38.3036C85.6294 38.3125 85.522 38.3435 85.3866 38.3963C85.2515 38.4493 85.1838 38.5197 85.1838 38.6054C85.1838 38.723 85.3248 38.7833 85.606 38.7833C85.7295 38.7833 85.9221 38.7597 86.1842 38.7156C86.4458 38.6717 86.64 38.6495 86.7665 38.6495C87.2195 38.6495 87.5741 38.7569 87.8287 38.9746C88.0831 39.191 88.2096 39.4923 88.2096 39.8777C88.2096 40.3027 88.0199 40.6456 87.639 40.9072C87.258 41.1691 86.7756 41.2986 86.1933 41.2986C85.8947 41.2986 85.5799 41.2456 85.2489 41.1397C84.9191 41.0335 84.6528 40.9042 84.4514 40.7513ZM86.1884 35.4472C85.9045 35.4472 85.6721 35.5474 85.4928 35.7486C85.3133 35.9502 85.2237 36.1944 85.2237 36.4812C85.2237 36.8033 85.3105 37.0696 85.484 37.2815C85.6576 37.4916 85.8929 37.5976 86.1886 37.5976C86.4782 37.5976 86.7049 37.4946 86.8697 37.2888C87.0328 37.0843 87.1154 36.8152 87.1154 36.4812C87.1154 36.1944 87.027 35.9505 86.8505 35.7486C86.6741 35.5474 86.4533 35.4472 86.1884 35.4472Z"
                fill="#2E3192"
              />
              <path
                d="M90.8137 40.2735C90.7063 40.5662 90.4841 40.8106 90.1429 41.0062C89.8031 41.2018 89.4221 41.2989 89.003 41.2989V40.5662C89.3458 40.5662 89.6385 40.4869 89.8826 40.3279C90.1268 40.1676 90.2488 39.9734 90.2488 39.7439C90.2488 39.4909 90.2047 39.2426 90.1149 38.9968C90.0266 38.751 89.9106 38.4467 89.7706 38.0849L88.5205 34.874H89.3575L90.7138 38.4423L91.9331 34.874H92.77L90.8137 40.2735Z"
                fill="#2E3192"
              />
              <path
                d="M96.8803 39.2351V41.2988H96.0625V34.8743H96.8803V35.2538C97.1905 34.9433 97.5658 34.7891 98.0053 34.7891C98.66 34.7891 99.1702 34.9919 99.535 35.4008C99.8998 35.8083 100.082 36.4056 100.082 37.1924C100.082 37.894 99.8984 38.4661 99.5308 38.9102C99.1632 39.3544 98.632 39.5763 97.9365 39.5763C97.7409 39.5763 97.5322 39.5413 97.3098 39.472C97.0862 39.4027 96.9436 39.3247 96.8803 39.2351ZM96.8803 35.8699V38.5587C96.9317 38.6367 97.0421 38.71 97.2083 38.7807C97.3744 38.8514 97.5362 38.8867 97.6951 38.8867C98.7114 38.8867 99.2204 38.3115 99.2204 37.1627C99.2204 36.5788 99.0997 36.1524 98.8584 35.8832C98.6173 35.6125 98.2306 35.4774 97.6993 35.4774C97.5847 35.4774 97.4433 35.5184 97.2771 35.598C97.1098 35.6786 96.9788 35.7698 96.8803 35.8699Z"
                fill="#2E3192"
              />
              <path
                d="M104.866 37.2612H101.539C101.539 37.8025 101.687 38.2175 101.983 38.5071C102.245 38.7601 102.58 38.8866 102.992 38.8866C103.459 38.8866 103.851 38.7499 104.164 38.4777L104.508 39.0675C104.382 39.194 104.188 39.3028 103.926 39.3954C103.599 39.5161 103.234 39.5761 102.832 39.5761C102.252 39.5761 101.759 39.3791 101.353 38.9849C100.903 38.5512 100.677 37.9687 100.677 37.2363C100.677 36.4742 100.909 35.8639 101.371 35.405C101.784 34.993 102.274 34.7886 102.841 34.7886C103.498 34.7886 104.014 34.9739 104.388 35.3445C104.75 35.7005 104.93 36.1726 104.93 36.7608C104.93 36.9433 104.909 37.1095 104.866 37.2612ZM102.88 35.4771C102.514 35.4771 102.208 35.5961 101.961 35.8316C101.725 36.0552 101.593 36.333 101.561 36.667H104.115C104.115 36.336 104.013 36.0608 103.805 35.8405C103.579 35.5978 103.27 35.4771 102.88 35.4771Z"
                fill="#2E3192"
              />
              <path
                d="M105.489 37.1714C105.489 36.4579 105.682 35.8828 106.066 35.4447C106.449 35.0077 106.955 34.7886 107.579 34.7886C108.241 34.7886 108.753 34.9986 109.118 35.4195C109.483 35.8403 109.665 36.4242 109.665 37.1714C109.665 37.9154 109.478 38.5024 109.105 38.9319C108.733 39.3614 108.224 39.5761 107.58 39.5761C106.922 39.5761 106.409 39.3583 106.041 38.9247C105.673 38.4906 105.489 37.9066 105.489 37.1714ZM106.351 37.1714C106.351 38.3288 106.762 38.9069 107.579 38.9069C107.962 38.9069 108.262 38.7527 108.478 38.4422C108.696 38.1318 108.804 37.7082 108.804 37.1716C108.804 36.0272 108.396 35.4563 107.58 35.4563C107.206 35.4563 106.909 35.6078 106.685 35.9124C106.463 36.2168 106.351 36.6373 106.351 37.1714Z"
                fill="#2E3192"
              />
              <path
                d="M111.346 39.2351V41.2988H110.528V34.8743H111.346V35.2538C111.656 34.9433 112.032 34.7891 112.471 34.7891C113.126 34.7891 113.636 34.9919 114.001 35.4008C114.366 35.8083 114.548 36.4056 114.548 37.1924C114.548 37.894 114.364 38.4661 113.997 38.9102C113.629 39.3544 113.098 39.5763 112.402 39.5763C112.207 39.5763 111.998 39.5413 111.776 39.472C111.552 39.4027 111.409 39.3247 111.346 39.2351ZM111.346 35.8699V38.5587C111.397 38.6367 111.508 38.71 111.674 38.7807C111.84 38.8514 112.002 38.8867 112.161 38.8867C113.177 38.8867 113.686 38.3115 113.686 37.1627C113.686 36.5788 113.565 36.1524 113.324 35.8832C113.083 35.6125 112.696 35.4774 112.165 35.4774C112.051 35.4774 111.909 35.5184 111.743 35.598C111.576 35.6786 111.444 35.7698 111.346 35.8699Z"
                fill="#2E3192"
              />
              <path
                d="M115.509 38.1833V32.9824H116.328V38.0465C116.328 38.2937 116.399 38.4879 116.541 38.6305C116.684 38.7717 116.869 38.8438 117.1 38.8438V39.5762C116.04 39.576 115.509 39.1113 115.509 38.1833Z"
                fill="#2E3192"
              />
              <path
                d="M121.935 37.2612H118.608C118.608 37.8025 118.756 38.2175 119.052 38.5071C119.314 38.7601 119.649 38.8866 120.061 38.8866C120.529 38.8866 120.92 38.7499 121.233 38.4777L121.577 39.0675C121.451 39.194 121.257 39.3028 120.995 39.3954C120.669 39.5161 120.304 39.5761 119.901 39.5761C119.321 39.5761 118.829 39.3791 118.422 38.9849C117.972 38.5512 117.746 37.9687 117.746 37.2363C117.746 36.4742 117.979 35.8639 118.44 35.405C118.854 34.993 119.343 34.7886 119.91 34.7886C120.567 34.7886 121.084 34.9739 121.457 35.3445C121.819 35.7005 122 36.1726 122 36.7608C122 36.9433 121.978 37.1095 121.935 37.2612ZM119.949 35.4771C119.583 35.4771 119.277 35.5961 119.03 35.8316C118.795 36.0552 118.662 36.333 118.63 36.667H121.185C121.185 36.336 121.082 36.0608 120.875 35.8405C120.648 35.5978 120.339 35.4771 119.949 35.4771Z"
                fill="#2E3192"
              />
            </svg>

            <div className={style.name}>{logo}</div>
            <div className={`${style.descr} text-capitalize`}>{version}</div>
          </div>
        </div>
        <PerfectScrollbar>
          <Menu
            onClick={handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={onOpenChange}
            mode="inline"
            className={style.navigation}
            inlineIndent="15"
          >
            {generateMenuItems()}
          </Menu>
        </PerfectScrollbar>
      </div>
    </Layout.Sider>
  )
}

export default withRouter(connect(mapStateToProps)(MenuLeft))
